export type Language = 'en' | 'sv';

interface Translations {
  [key: string]: string;
}

type TranslationsMap = {
  [K in Language]: Translations;
};

export const translations: TranslationsMap = {
    en: {
      welcome: "HI! Don't waste your time here, check the About page!",
      home: "Home",
      about: "About",
      timeline: "Timeline",
      gameOverMessage: "Okay, if you really want to get to know me better I think at this point we should meet up for a coffee!",
      playAgain: "Play Again!",
      finalScore: "Your final score:",
      score: "Score:",
      followMe: "Follow me",
      trueFalseGameTitle: "True or False",
      true: "True",
      false: "False",
      game1Title: "idk lol",
      game3Title: "idk here either lol",
      carestream: "Carestream Dental",
      carestreamDetails: "Worked on developing a new web-based system with a focus on collaboration and React technologies.",
      protector: "Protector",
      protectorDetails: "Contributed to the development and maintenance of the customer portal for claims management.",
      kammarkollegiet: "Kammarkollegiet",
      kammarkollegietDetails: "Part of a team developing a digital system for employer compensation disbursement.",
      ving: "Ving",
      vingDetails: "Involved in web development and process improvement for payment flows and user experience on Ving’s platform.",
      skatteverket: "Skatteverket",
      skatteverketDetails: "Managed API communication between internal systems, and facilitated Scrum activities.",
      magello: "Magello",
      magelloDetails: "Led the development of a web application for organizing conferences and events.",
      scania: "Scania",
      scaniaDetails: "Led the development of a tool for visualizing software usage in Scania’s product modeling.",
      trendhim: "Trendhim",
      trendhimDetails: "Oversaw customer service teams across multiple countries, enhancing customer engagement strategies.",
      certifiedAccessibility: "CPACC",
      accessibilityDetails: "Certified by IAAP in 2023, specializing in web accessibility and inclusive design.",
      certifiedSAFe: "Certified SAFe Professional",
      safeDetails: "Achieved certification in SAFe 5 by Scaled Agile Inc in 2022.",
      certifiedScrumMaster: "Certified Scrum Master",
      scrumMasterDetails: "Scrum Master certification obtained in 2021.",
      publicationB3TechTalk: "B3 Tech Talk",
      b3TechTalkDetails: "Presented on WCAG 2.1 and web accessibility in 2023.",
      publicationCoachChangersHub: "Coach Changer's Hub",
      coachChangersHubDetails: "Mentored students at Changer's Hub in 2023.",
      publicationDatatjejPodcast: "Gäst i DataTjejs podcast",
      datatjejPodcastDetails: "Guest appearance on the DataTjej podcast in 2022 discussing the importance of representation in IT.",
    },
    sv: {
      welcome: "HEJ! Slösa inte bort tiden här, spana in Om-sidan!",
      home: "Hem",
      about: "Om",
      timeline: "Tidslinje",
      gameOverMessage: "Okej, om du verkligen vill lära känna mig bättre tycker jag att vi borde träffas på en kaffe vid det här laget!",
      playAgain: "Spela igen!",
      finalScore: "Ditt slutgiltiga resultat:",
      score: "Poäng:",
      followMe: "Följ mig",
      trueFalseGameTitle: "Sant eller Falskt",
      true: "Sant",
      false: "Falskt",
      game1Title: "vene lol",
      game3Title: "vene här heller lol",
      carestream: "Carestream Dental",
      carestreamDetails: "Arbetade med att utveckla ett nytt webbaserat system med fokus på samarbete och React-teknik.",
      protector: "Protector",
      protectorDetails: "Bidrog till utveckling och underhåll av kundportalen för skadehantering.",
      kammarkollegiet: "Kammarkollegiet",
      kammarkollegietDetails: "Deltog i ett team som utvecklar ett digitalt system för utbetalning av ersättningar till arbetsgivare.",
      ving: "Ving",
      vingDetails: "Deltog i webbutveckling och förbättring av betalningsflöden och användarupplevelse på Vings plattform.",
      skatteverket: "Skatteverket",
      skatteverketDetails: "Ansvarade för API-kommunikation mellan interna system och faciliterade Scrum-aktiviteter.",
      magello: "Magello",
      magelloDetails: "Ledde utvecklingen av en webbapplikation för att organisera konferenser och evenemang.",
      scania: "Scania",
      scaniaDetails: "Ledde utvecklingen av ett verktyg för att visualisera mjukvaruanvändning i Scanias produktmodellering.",
      trendhim: "Trendhim",
      trendhimDetails: "Övervakade kundtjänstteam i flera länder och förbättrade kundengagemangsstrategier.",
      certifiedAccessibility: "CPACC",
      accessibilityDetails: "Certifierad av IAAP 2023, specialiserad på webbtillgänglighet och inkluderande design.",
      certifiedSAFe: "Certifierad SAFe Professional",
      safeDetails: "Certifierad i SAFe 5 av Scaled Agile Inc 2022.",
      certifiedScrumMaster: "Certifierad Scrum Master",
      scrumMasterDetails: "Scrum Master-certifiering erhölls 2021.",
      publicationB3TechTalk: "B3 Tech Talk",
      b3TechTalkDetails: "Presenterade om WCAG 2.1 och webbtillgänglighet 2023.",
      publicationCoachChangersHub: "Coach Changer's Hub",
      coachChangersHubDetails: "Mentor för studenter vid Changer's Hub 2023.",
      publicationDatatjejPodcast: "Gäst i DataTjejs podcast",
      datatjejPodcastDetails: "Gästade DataTjej-podden 2022 och diskuterade vikten av representation inom IT.",
    },
  };
  